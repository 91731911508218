body {
  /* margin: 0; */
  /* background-color: #000; */
  /* color: #eee; */
  font-family: Poppins, sans-serif;
  /* font-size: 12px; */
}

a {
  text-decoration: none;
}

header {
  /* width: 1140px; */
  max-width: 100%;
  margin: auto;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
}

header a {
  color: #ffbf00;
  margin-right: 40px;
  font-weight: 500;
  /* font-size: 18px; */
  text-shadow: 1px 2px #000;
}

/* carousel */
.carousel {
  /* height: 100vh; */
  margin-top: -50px;
  /* width: 100vw; */
  overflow: hidden;
  position: relative;
}

.carousel .list .item {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0 0 0 0;
}

.carousel .list .item img {
  
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: none; /* Make transitions instant */
}


.carousel .list .item .content {
  position: absolute;
  /* z-index: 2; */
  top: 20%;
  width: 1140px;
  max-width: 80%;
  left: 50%;
  transform: translateX(-50%);
  padding-right: 30%;
  box-sizing: border-box;
  color: #fff;
  text-shadow: 0 5px 10px #0004;
}

.carousel .list .item .author {
  font-weight: bold;
  letter-spacing: 10px;
  /* text-shadow: 10px 10px black; */
}

.carousel .list .item .title,
.carousel .list .item .topic {
  /* font-size: 5em; */
  font-weight: bold;
  line-height: 1.3em;
}




.carousel .list .item .topic {
  color: #ffbf00;
}

.carousel .list .item .buttons {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-template-rows: 40px;
  gap: 5px;
  margin-top: 20px;
}

.carousel .list .item .buttons button {
  width: fit-content;
  
  height: fit-content;
  border: none;
  color: black;
  background-color: #ffbf00;
  letter-spacing: 3px;
  font-family: Poppins;
  font-weight: 500;
  transition: .5s;

}
.carousel .list .item .buttons button:hover{
  background-color: #fff;
  color: #000;
}
.carousel .list .item .buttons button:nth-child(2) {
  background-color: transparent;
  border: 1px solid #fff;
  color: #eee;
}

/* thumbnail */
.thumbnail {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: max-content;
  z-index: 100;
  display: flex;
  gap: 20px;
  transition: none; /* Make transitions instant */
}

.thumbnail .item {
  /* width: 150px; */
  /* height: 220px; */
  flex-shrink: 0;
  position: relative;
}

.thumbnail .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.thumbnail .item .content {
  color: #fff;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
}

.thumbnail .item .content .title {
  font-weight: 500;
}

.thumbnail .item .content  {
  font-weight: 300;
}

/* arrows */
.arrows {
  position: absolute;
  top: 80%;
  right: 52%;
  z-index: 100;
  width: 300px;
  max-width: 30%;
  display: flex;
  gap: 10px;
  align-items: center;
}

.arrows button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffbf00;
  border: none;
  color: #fff;
  font-family: monospace;
  font-weight: bold;
  transition: .5s;
}

.arrows button:hover {
  background-color: #fff;
  color: #000;
}

/* animation */
.carousel .list .item:nth-child(1) {
  z-index: 1;
}
.text-shadow-black {
  text-shadow: 1px 1px 0 #000;
}

/* animation text in first item */
.carousel .list .item:nth-child(1) .content .author,
.carousel .list .item:nth-child(1) .content .title,
.carousel .list .item:nth-child(1) .content .topic,
.carousel .list .item:nth-child(1) .content .des,
.carousel .list .item:nth-child(1) .content .buttons {
  text-shadow: 2px 2px #000;
  transform: translateY(50px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent .5s 1s linear 1 forwards;
  /* z-index: 100; */
}

@keyframes showContent {
  to {
      transform: translateY(0px);
      filter: blur(0px);
      opacity: 1;
  }
}

.carousel .list .item:nth-child(1) .content .title {
  animation-delay: 1.2s !important;
  
}

.carousel .list .item:nth-child(1) .content .topic {
  animation-delay: 1.4s !important;
}

.carousel .list .item:nth-child(1) .content .des {
  animation-delay: 1.6s !important;
}

.carousel .list .item:nth-child(1) .content .buttons {
  animation-delay: 1.8s !important;
}

/* create animation when next click */
.carousel.next .list .item:nth-child(1) img {
  width: 150px;
  height: 220px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  border-radius: 30px;
  animation: showImage .5s linear 1 forwards;
}

@keyframes showImage {
  to {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
  }
}

.carousel.next .thumbnail .item:nth-last-child(1) {
  overflow: hidden;
  animation: showThumbnail .5s linear 1 forwards;
}

.carousel.prev .list .item img {
  z-index: 100;
}

@keyframes showThumbnail {
  from {
      width: 0;
      opacity: 0;
  }
}

.carousel.next .thumbnail {
  animation: effectNext .5s linear 1 forwards;
  transition: none; /* Make transitions instant */
}

@keyframes effectNext {
  from {
      transform: translateX(150px);
  }
  to {
      transform: translateX(0);
  }
}

/* running time */
.carousel .time {
  position: absolute;
  z-index: 1000;
  width: 0%;
  height: 3px;
  background-color: #ffffff;
  left: 0;
  top: 0;
}

.carousel.next .time,
.carousel.prev .time {
  animation: runningTime 3s linear 1 forwards;
}

@keyframes runningTime {
  from {
      width: 100%;
  }
  to {
      width: 0;
  }
}

/* prev click */
.carousel.prev .list .item:nth-child(2) {
  z-index: 2;
}

.carousel.prev .list .item:nth-child(2) img {
  animation: outFrame 0.5s linear 1 forwards;
  position: absolute;
  bottom: 0;
  left: 0;
}

@keyframes outFrame {
  to {
      width: 150px;
      height: 220px;
      bottom: 50px;
      left: 50%;
      border-radius: 20px;
  }
}

.carousel.prev .thumbnail .item:nth-child(1) {
  overflow: hidden;
  opacity: 0;
  animation: showThumbnail .5s linear 1 forwards;
}

.carousel.next .arrows button,
.carousel.prev .arrows button {
  pointer-events: none;
 
}

.author {
  color: #ffbf00;
  
  /* font-size: 20px; */
  text-shadow: 1px 2px black;
}

.carousel.prev .list .item:nth-child(2) .content .author,
.carousel.prev .list .item:nth-child(2) .content .title,
.carousel.prev .list .item:nth-child(2) .content .topic,
.carousel.prev .list .item:nth-child(2) .content .des,
.carousel.prev .list .item:nth-child(2) .content .buttons {
  animation: contentOut 1.5s linear 1 forwards !important;
}

@keyframes contentOut {
  to {
      transform: translateY(-150px);
      filter: blur(20px);
      opacity: 0;
  }
}

@media screen and (max-width: 678px) {
  .carousel .list .item .content {
      padding-right: 0;
  }

  .carousel .list .item .content .title {
      /* font-size: 30px; */
  }
}

.logo {
  z-index: 20;
  position: absolute;
  width: 200px;
  left: -200px;
  top: 180px;
}
.gradient{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.1));
  z-index: 1;
  opacity:0.5;

}
 @media(max-width:560px){
  .content{
    
    margin-left: -90px;
  }
  .title{
    letter-spacing: -10px;
  }
  .arrows button{
    width: 100px;
    height: 100px;
    font-size: 40px;
    
  }
 }
