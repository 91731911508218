@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: 'CustomFont';
  src: url('./assets/fonts/Brandon_bld_it.otf') format('Brandon_bld')
  /* Add additional font formats if necessary */
  /* Specify the correct path to the font files */
}
*{
font-family: 'CustomFont',sans-serif;
}
.parallax-container {
    position: relative;
    overflow: hidden;
    /* height: 100vh; Full viewport height */
  }
  .yel {
    color: #ffbf00;
    
    /* font-size: 20px; */
    text-shadow: 1px 2px black;
  }
  #butto:hover{
    box-shadow: inset 0 -41px 0 -1px rgb(5, 162, 5);
    color: white;
  }
  
  .gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0.1));
    z-index: 1;
    opacity:0.5;
  
  }
  .parallax-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    z-index: -1; /* Behind the content */
  }
  
  .parallax-content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    text-align: center;
  }
  